<template>
  <div class="pageBar">
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: "PageBar"
};
</script>
<style>
.pageBar {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
}
</style>
