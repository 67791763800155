<template>
    <el-table :data="serviceList" border style="width: 100%" max-height="300">
        <el-table-column prop="cdsvnmNm" label="サービス名" min-width="200px"></el-table-column>
        <el-table-column prop="cdplnm" label="商品名" min-width="200px"></el-table-column>
        <el-table-column prop="cdpbarNm" label="掲載エリア" min-width="150px"></el-table-column>
        <el-table-column label="リスティング情報" min-width="200px">
            <template slot-scope="scope">
                <div v-if="scope.row.cdlti1Nm">① {{scope.row.cdlti1Nm}}</div>
                <div v-if="scope.row.cdlti2Nm">② {{scope.row.cdlti2Nm}}</div>
            </template>
        </el-table-column>
        <el-table-column prop="cdslcn" label="数量" min-width="80px"></el-table-column>
        <el-table-column prop="cdterm" label="期間" min-width="80px"></el-table-column>
        <el-table-column label="開始日" min-width="110px">
            <template slot-scope="scope">
                <span>{{ scope.row.strCdstrd }}</span>
            </template>
        </el-table-column>
        <el-table-column label="終了日" min-width="110px">
            <template slot-scope="scope">
                <span>{{ scope.row.strCdendd }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="cdsprc" label="合計額" min-width="120px">
            <template slot-scope="scope">
                <div>{{scope.row.cdsprc | currency('¥', 0)}}</div>
            </template>
        </el-table-column>
        <el-table-column label="CP" min-width="200px">
            <template slot-scope="scope">
                <div v-if="scope.row.cdcpn1Nm">① {{scope.row.cdcpn1Nm}}</div>
                <div v-if="scope.row.cdcpn2Nm">② {{scope.row.cdcpn2Nm}}</div>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
    import { getList } from "@/api/service";
    import { isValidResponse,currency } from "@/utils/commonUtils";

    export default {
        name: "CargoPickList",
        props: {
            mcltid: String,
        },
        filters: {
            currency: currency
        },
        data: () => {
            return {
                serviceList: []
            };
        },
        mounted() {
            this.initServiceList();
        },
        methods: {
            initServiceList() {
                var zero = function (value) {
                    if (value < 10) {
                        return '0' + value;
                    }
                    return value;
                };
                var longToDate = function (value) {
                    var myDate = new Date(value);
                    var year = myDate.getFullYear();
                    var month = zero(myDate.getMonth() + 1);
                    var day = zero(myDate.getDate());
                    return  year + "-" + month + "-" + day;
                };
                getList({
                    customerId: this.mcltid,
                }).then((response) => {
                    if (isValidResponse(response)) {
                        this.serviceList = response.data.items;
                        for (var i = 0; i < this.serviceList.length; i++) {         
                            this.serviceList[i].strCdstrd = longToDate(this.serviceList[i].cdstrd);
                            this.serviceList[i].strCdendd = longToDate(this.serviceList[i].cdendd);
                        }
                    }
                });
            }
        },
    };
</script>
<style scoped></style>
