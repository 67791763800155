<template>
  <div class="searchBar2">
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: "SearchBar"
};
</script>
<style>
.searchBar2 {
  margin: 0px 0px 10px 0px;
  /*padding: 10px 10px ;*/
  /*background-color: #f7f7f7;*/
  text-align: left;
}
</style>
