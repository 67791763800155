<template>
<div>
    <el-row>
        <el-col style="text-align:left">
            <SearchBar>
                <el-input size="small" v-model="searchParams.customerName" placeholder="keyword" clearable style="width:160px;"></el-input>
                <el-select size="small" v-model="searchParams.statusArray" multiple collapse-tags placeholder="処理状態" style="width: 165px;margin-left:10px;">
                    <el-option v-for="item in statusList"
                        v-bind:key="item.value"
                        v-bind:label="item.label"
                        v-bind:value="item.value">
                    </el-option>
                </el-select>
                <el-select size="small" v-model="searchParams.aptsts" placeholder="申込状況" clearable style="width: 120px;margin-left:10px;">
                    <el-option v-for="item in aptstsList"
                        v-bind:key="item.value"
                        v-bind:label="item.label"
                        v-bind:value="item.value">
                    </el-option>
                </el-select>
                <el-select size="small" v-model="searchParams.opstts" placeholder="OP状況" clearable style="width: 120px;margin-left:10px;">
                    <el-option v-for="item in opsttsList"
                        v-bind:key="item.value"
                        v-bind:label="item.label"
                        v-bind:value="item.value">
                    </el-option>
                </el-select>
                <el-date-picker size="small" type="date" v-model="searchParams.applyDate" placeholder="申込日" 
                    format="yyyy/MM/dd" value-format="yyyy/MM/dd" style="width: 140px;margin-left:10px;">
                </el-date-picker>
                <el-date-picker size="small" type="date" v-model="searchParams.cdstrd" placeholder="掲載開始日" 
                    format="yyyy/MM/dd" value-format="yyyy/MM/dd" style="width: 140px;margin-left:10px;">
                </el-date-picker>
                <el-date-picker size="small" type="date" v-model="searchParams.dateTimeFrom" placeholder="承認開始日" 
                    format="yyyy/MM/dd" value-format="yyyy/MM/dd" style="width: 140px;margin-left:10px;">
                </el-date-picker>
                <el-date-picker size="small" type="date" v-model="searchParams.dateTimeTo" placeholder="承認終了日" 
                    format="yyyy/MM/dd" value-format="yyyy/MM/dd" style="width: 140px;margin-left: 10px;">
                </el-date-picker>
                <el-select size="small" v-model="searchParams.groupType" placeholder="案件区分" clearable style="width: 120px;margin-left:10px;" v-if="Number(role) <= 3">
                    <el-option v-for="item in groupTypeList"
                        v-bind:key="item.value"
                        v-bind:label="item.label"
                        v-bind:value="item.value">
                    </el-option>
                </el-select>
                <el-button size="small" type="primary" style="margin-left: 10px;width: 90px;" icon="el-icon-search" round @click="handleSearch">{{$t('search.search')}}</el-button>
                <el-button size="small" type="info" style="margin-left: 5px;width: 90px;" round @click="handleReset">{{$t('search.reset')}}</el-button>
            </SearchBar>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="16" style="line-height:40px">
            <span class="font-count">{{count}}</span>件
            <el-switch v-model="searchParams.isExpand" active-text="明細展開" inactive-text="" @change="handleExpand" style="margin-left:20px"></el-switch>
            <el-button size="mini" type="success" style="margin-left: 20px;" round @click="handleSearchSub" v-if="Number(role) <= 3">確認待 ({{subCount}}件)</el-button>
            <el-button size="mini" type="warning" style="margin-left: 5px;" round @click="handleSearchApvl" v-if="Number(role) <= 3">承認済 ({{apvlCount}}件)</el-button>
            <el-button size="mini" type="info" style="margin-left: 5px;" round @click="handleSearchRef" v-if="Number(role) <= 3">差し戻し ({{refCount}}件)</el-button>
            <el-button size="mini" type="danger" style="margin-left: 5px;" round @click="handleSearchErr" v-if="Number(role) <= 3">エラー ({{errCount}}件)</el-button>
        </el-col>
        <el-col :span="8" style="text-align:right;line-height:40px">
            <el-button size="small" type="primary" icon="el-icon-plus" round @click="handleAdd">新規</el-button>
            <!-- <el-button size="small" type="success" round @click="handleExport"  style="margin-left:15px">エクスポート</el-button> -->
        </el-col>
    </el-row>
</div>
    
</template>
<script>
    import SearchBar from "@/components/common/SearchBar.vue";
    export default {
        name: "SearchCriterial",
        components: {SearchBar},
        props: {
            handleSearch: Function,
            handleSearchSub: Function,
            handleSearchApvl: Function,
            handleSearchRef: Function,
            handleSearchErr: Function,
            handleReset: Function,
            handleAdd: Function,
            handleExport: Function,
            handleExpand: Function,
            searchParams: Object,
            count: Number,
            subCount: Number,
            apvlCount: Number,
            errCount: Number,
            refCount: Number,
            role: String
        },
        data: () => {
            return {
                statusList: [
                    {value:-1, label: "キャンセル"},
                    {value:0, label: "作成中"},
                    {value:1, label: "確認待"},
                    {value:2, label: "確認済"},
                    {value:3, label: "処理中"},
                    {value:4, label: "処理済"},
                    {value:99, label: "エラー"},
                    {value:-99, label: "削除済"},
                ],
                aptstsList: [
                    {value:0, label: "差戻中"},
                    {value:1, label: "完了"},
                ],
                opsttsList: [
                    {value:1, label: "有"},
                    {value:2, label: "紐づけ待ち"},
                    {value:3, label: "入稿済"},
                ],
                groupTypeList: [
                    {value:1, label: "自社案件"},
                    {value:2, label: "二次店案件"},
                ],
            };
        },
        mounted() {
        },
        methods: {
        },
    };
</script>
<style>
.font-count{
    color: #409EFF;
    font-weight: bold;
    font-size: 24px;
}
</style>
