<template>
    <div>
        <div>
            <SearchCriterial
                    :handleSearch="handleSearch"
                    :handleSearchSub="handleSearchSub"
                    :handleSearchApvl="handleSearchApvl"
                    :handleSearchRef="handleSearchRef"
                    :handleSearchErr="handleSearchErr"
                    :handleReset="handleReset"
                    :handleAdd="handleAdd"
                    :handleExport="handleExport"
                    :handleExpand="handleExpand"
                    :searchParams="searchParams"
                    :count="totalCount"
                    :subCount="subCount"
                    :apvlCount="apvlCount"
                    :errCount="errCount"
                    :refCount="refCount"
                    :role="accountInfo.role"
            />
            <el-table :data="items" row-key="mcltid" :expand-row-keys="expands" :row-class-name="tableRowClassName" style="width: 100%;padding: 0px 0px 0px 0px;">
                <el-table-column type="expand" label="" width="40px" align="center">
                    <template slot-scope="scope">
                        <CargoPickList :mcltid="scope.row.mcltid"/>
                    </template>
                </el-table-column>
                <el-table-column prop="mcltid" label="ID" width="70px">
                </el-table-column>
                <el-table-column prop="clnmkj" label="本社名（漢字）" min-width="170px">
                </el-table-column>
                <el-table-column label="募集先" min-width="65px" align="center">
                    <template slot-scope="scope">
                        <el-tooltip>
                            <div slot="content" v-html="toBreak(scope.row.advtkjNm)"></div>
                            <div style="text-align: center;font-size: 28px;">
                                <i class="el-icon-office-building" v-if="scope.row.advtkjNm" style="color:blue;"></i>
                                <i class="el-icon-office-building" v-else></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="clcomp" label="COMP" width="80px">
                </el-table-column>
                <el-table-column :label="$t('listCustomer.status')" align="center" width="480px" v-if="accountInfo.role <= 3">
                    <el-table-column label="Master" width="100px" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.clwss0 == 2 && scope.row.clwdt0 == null">―</span> 
                            <el-tooltip>
                                <div slot="content" v-if="scope.row.rpasvid0 != null">{{scope.row.rpasvid0}}</div>
                                <div slot="content" v-else></div>
                                <span v-if="scope.row.clwss0 == 0">
                                    <span v-if="scope.row.prcsst == 99" style="color:red;">処理異常</span>
                                    <span v-else>処理待</span>
                                </span>
                                <span v-if="scope.row.clwss0 == 1" style="color:blue;">処理中</span> 
                            </el-tooltip>
                            <el-tooltip v-if="scope.row.clwdt0 != null">
                                <div slot="content" v-if="scope.row.rpasvid0 != null">{{scope.row.rpasvid0}}<br/>{{scope.row.clwdt0}}</div>
                                <div slot="content" v-else>{{scope.row.clwdt0}}</div>
                                <span v-if="scope.row.clwss0 == 2" style="color:green;">処理完了</span> 
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="CloudSign" width="100px" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.clwss1 == 2 && scope.row.clwdt1 == null">―</span> 
                            <el-tooltip>
                                <div slot="content" v-if="scope.row.rpasvid1 != null">{{scope.row.rpasvid1}}</div>
                                <div slot="content" v-else></div>
                                <span v-if="scope.row.clwss1 == 0">
                                    <span v-if="scope.row.clwss0 != 0 && scope.row.prcsst == 99" style="color:red;">処理異常</span>
                                    <span v-else>処理待</span>
                                </span>
                                <span v-if="scope.row.clwss1 == 1 && scope.row.clwdt1 == null" style="color:blue;">処理中</span> 
                                <span v-if="scope.row.clwss1 == 1 && scope.row.clwdt1 != null" style="color:blue;">確認メール待</span> 
                            </el-tooltip>
                            <el-tooltip v-if="scope.row.clwdt1 != null">
                                <div slot="content" v-if="scope.row.rpasvid1 != null">{{scope.row.rpasvid1}}<br/>{{scope.row.clwdt1}}</div>
                                <div slot="content" v-else>{{scope.row.clwdt1}}</div>
                                <span v-if="scope.row.clwss1 == 2" style="color:green;">処理完了</span> 
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="DIP" width="90px" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.clwss2 == 2 && scope.row.clwdt2 == null">―</span> 
                            <el-tooltip>
                                <div slot="content" v-if="scope.row.rpasvid2 != null">{{scope.row.rpasvid2}}</div>
                                <div slot="content" v-else></div>
                                <span v-if="scope.row.clwss2 == 0">
                                    <span v-if="scope.row.clwss0 != 0 && scope.row.clwss1 != 0 && scope.row.prcsst == 99" style="color:red;">処理異常</span>
                                    <span v-else>処理待</span>
                                </span>
                                <span v-if="scope.row.clwss2 == 1" style="color:blue;">処理中</span>
                            </el-tooltip>
                            <el-tooltip v-if="scope.row.clwdt2 != null">
                                <div slot="content" v-if="scope.row.rpasvid2 != null">{{scope.row.rpasvid2}}<br/>{{scope.row.clwdt2}}</div>
                                <div slot="content" v-else>{{scope.row.clwdt2}}</div>
                                <span v-if="scope.row.clwss2 == 2" style="color:green;">処理完了</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="SAAS" width="90px" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.clwss3 == 2 && scope.row.clwdt3 == null">―</span>
                            <el-tooltip>
                                <div slot="content" v-if="scope.row.rpasvid3 != null">{{scope.row.rpasvid3}}</div>
                                <div slot="content" v-else></div>
                                <span v-if="scope.row.clwss3 == 0">
                                    <span v-if="scope.row.clwss0 != 0 && scope.row.clwss1 != 0 && scope.row.clwss2 != 0 && scope.row.prcsst == 99" style="color:red;">処理異常</span>
                                    <span v-else>処理待</span>
                                </span>
                                <span v-if="scope.row.clwss3 == 1" style="color:blue;">処理中</span>
                            </el-tooltip>
                            <el-tooltip v-if="scope.row.clwdt3 != null">
                                <div slot="content" v-if="scope.row.rpasvid3 != null">{{scope.row.rpasvid3}}<br/>{{scope.row.clwdt3}}</div>
                                <div slot="content" v-else>{{scope.row.clwdt3}}</div>
                                <span v-if="scope.row.clwss3 == 2" style="color:green;">処理完了</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="BIMS" width="90px" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.clwss4 == 2 && scope.row.clwdt4 == null">―</span>
                            <el-tooltip>
                                <div slot="content" v-if="scope.row.rpasvid4 != null">{{scope.row.rpasvid4}}</div>
                                <div slot="content" v-else></div>
                                <span v-if="scope.row.clwss4 == 0">
                                    <span v-if="scope.row.clwss0 != 0 && scope.row.clwss1 != 0 && scope.row.clwss2 != 0 && scope.row.clwss3 != 0 && scope.row.prcsst == 99" style="color:red;">処理異常</span>
                                    <span v-else>処理待</span>
                                </span>
                                <span v-if="scope.row.clwss4 == 1" style="color:blue;">処理中</span>
                            </el-tooltip>
                            <el-tooltip  v-if="scope.row.clwdt4 != null">
                                <div slot="content" v-if="scope.row.rpasvid4 != null">{{scope.row.rpasvid4}}<br/>{{scope.row.clwdt4}}</div>
                                <div slot="content" v-else>{{scope.row.clwdt4}}</div>
                                <span v-if="scope.row.clwss4 == 2" style="color:green;">処理完了</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="処理状態" width="95px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.cldlcd == 0 && scope.row.prcsst == -1" style="color:blue;">キャンセル</span> 
                        <span v-if="scope.row.cldlcd == 0 && scope.row.prcsst == 0">作成中</span>
                        <span v-if="scope.row.cldlcd == 0 && scope.row.prcsst == 1">確認待</span> 
                        <span v-if="scope.row.cldlcd == 0 && scope.row.prcsst == 2">確認済</span> 
                        <span v-if="scope.row.cldlcd == 0 && scope.row.prcsst == 3">処理中</span> 
                        <span v-if="scope.row.cldlcd == 0 && scope.row.prcsst == 4" style="color:green;">処理済</span> 
                        <el-tooltip>
                            <div slot="content">{{scope.row.errMsg}}</div>
                            <span v-if="scope.row.cldlcd == 0 && scope.row.prcsst == 99" style="color:red;">エラー</span> 
                        </el-tooltip>
                        <span v-if="scope.row.cldlcd == 1" style="color:#ffcc00;">削除済</span> 
                    </template>
                </el-table-column>
                <el-table-column label="申込ステータス" align="center">
                    <el-table-column label="申込状況" width="95px" align="center">
                        <template slot-scope="scope">
                            <el-tooltip v-if="scope.row.aptsts == 0">
                                <div slot="content">{{scope.row.aptcmd}}</div>
                                <span style="color:red;">差戻中</span>
                            </el-tooltip>
                            <span v-if="scope.row.aptsts == 1" style="color:green;">完了</span> 
                            <el-button type="primary" size="mini" plain round @click="showUpdAptsts(scope.row.mcltid)" v-if="scope.row.aptsts != 1 && scope.row.cldlcd == 0 && scope.row.prcsst >= 0 && accountInfo.role <= 3">更新</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="契約ID" align="center" width="80px" v-if="accountInfo.role <= 3">
                        <template slot-scope="scope">
                            <span v-if="scope.row.cbctid <= 0">―</span>
                            <span v-else>{{scope.row.cbctid}}</span> 
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="cbapdt" :label="$t('listCustomer.applyDate')" :formatter="formatApplyDate" width="105px"></el-table-column> -->
                    <el-table-column :label="$t('listCustomer.applyDate')" width="105px" align="center" v-if="accountInfo.role <= 3">
                        <template slot-scope="scope">
                            <div v-if="scope.row.apltdt" style="cursor: pointer;" @click="showUpdApltdt(scope.row.mcltid)">{{formatApplyDate(scope.row.apltdt)}}</div>
                            <div v-else style="cursor: pointer;color:red;" @click="showUpdApltdt(scope.row.mcltid)">未設定</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="OP" width="105px" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.opstts == 1">　有　</span> 
                            <span v-if="scope.row.opstts == 2" style="color:red;">原稿紐づけ待</span> 
                            <span v-if="scope.row.opstts == 3" style="color:blue;">入稿済</span> 
                            <span v-if="scope.row.opstts == 4" style="color:green;">完了送信済</span>
                            <el-button type="primary" size="mini" plain round @click="showUpdOpstts(scope.row.mcltid)" v-if="scope.row.cldlcd == 0 && scope.row.prcsst >= 0 && accountInfo.role <= 3">更新</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bizno" label="お仕事NO" width="120px" align="center">
                    </el-table-column>
                    <el-table-column label="備考" min-width="65px" align="center" v-if="accountInfo.role <= 3">
                        <template slot-scope="scope">
                            <el-tooltip>
                                <div slot="content" v-html="toBreakNew(scope.row.cstcmd)"></div>
                                <div style="text-align: center;font-size: 28px;cursor: pointer;" @click="showUpdCstcmd(scope.row.mcltid)">
                                    <i class="el-icon-chat-dot-round" v-if="scope.row.cstcmd" style="color:blue;"></i>
                                    <i class="el-icon-chat-dot-round" v-else></i>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column prop="groupName" label="グループ" min-width="140px">
                </el-table-column>
                <el-table-column prop="clcrmb" :label="$t('listCustomer.createUser')" min-width="120px">
                </el-table-column>
                <el-table-column :label="$t('listCustomer.createTime')" width="95px">
                    <template slot-scope="scope">
                        <!-- <span style="font-size:12px;">{{scope.row.clcrdt}}</span> -->
                        <span style="font-size:12px;">{{formatDateHHmi(scope.row.cmapdt)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="承認日時" width="95px">
                    <template slot-scope="scope">
                        <span style="font-size:12px;">{{formatDateHHmi(scope.row.apvldt)}}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" :label="$t('task.process')" width="280px">
                    <template slot-scope="scope">
                        <div>
                            <el-button type="primary" size="mini" plain round @click="handleUpdate(scope.row.mcltid)"  style="width:72px;" v-if="scope.row.cldlcd == 0 && ((scope.row.prcsst >= 0 && scope.row.prcsst <= 1) || scope.row.prcsst == 99)">修正</el-button>
                            <el-button type="primary" size="mini" plain round @click="handleConfirm(scope.row.mcltid)" style="width:92px;" v-if="scope.row.cldlcd == 0 && scope.row.prcsst == 1 && accountInfo.role <= 2">依頼確認</el-button>
                            <el-button type="primary" size="mini" plain round @click="handleCopy(scope.row.mcltid)"  style="width:72px;" v-if="scope.row.cldlcd == 0 && scope.row.prcsst <= 4">コピー</el-button>
                            <el-tooltip content="エラー画像">
                                <el-image size="mini" style="width:50px;height:30px;position:absolute;margin-left:15px;" :src="getImgUrl(scope.row.mcltid)" :preview-src-list="[getImgUrl(scope.row.mcltid)]" v-if="scope.row.prcsst == 99">
                                </el-image>
                            </el-tooltip>
                        </div>
                        <div :style="(scope.row.cldlcd != 1) ? 'margin-top:8px' : ''">
                            <el-dropdown v-if="scope.row.prcsst >= 1 || scope.row.prcsst == -1" @command="handlePDF">
                                <el-button type="success" size="mini" plain round style="margin-right:10px">
                                    PDF<i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="{cmd:'pp',id:scope.row.mcltid}" v-if="accountInfo.role <= 3">レビュー</el-dropdown-item>
                                    <el-dropdown-item :command="{cmd:'pa',id:scope.row.mcltid}" v-if="accountInfo.role == 4">レビュー</el-dropdown-item>
                                    <el-dropdown-item :command="{cmd:'cp',id:scope.row.mcltid}" v-if="accountInfo.role <= 3">契約</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-button type="danger" size="mini" plain round @click="handleCancel(scope.row.mcltid)" v-if="scope.row.cldlcd == 0 && scope.row.prcsst >= 0 && accountInfo.role <= 3 && scope.row.prcsst != 3">キャンセル</el-button>
                            <el-button type="danger" size="mini" plain round @click="handleDelete(scope.row.mcltid)" style="width:72px;" v-if="accountInfo.role == 1 && scope.row.cldlcd == 0 && (scope.row.prcsst < 2 || scope.row.prcsst == 3)">削除</el-button>
                            <el-button type="danger" size="mini" plain round @click="handleDelete(scope.row.mcltid)" style="width:72px;" v-if="accountInfo.role > 3 && scope.row.cldlcd == 0 && (scope.row.prcsst == 0 || scope.row.prcsst == 1)">削除</el-button>
                            <el-button type="primary" size="mini" plain round @click="handleRecover(scope.row.mcltid)" style="width:72px;" v-if="accountInfo.role == 1 && scope.row.cldlcd == 1">回復</el-button>
                            <el-button type="warning" size="mini" plain round @click="handleRerun(scope.row.mcltid)" 
                                v-if="accountInfo.groupId == 1 && accountInfo.role == 1 && (scope.row.prcsst == 99 || scope.row.prcsst == 3) && scope.row.errMsg !='契約ID取得失敗'">再実行</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <PageBar>
                <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="totalCount"
                        @current-change="handleCurrentChange"
                        :current-page.sync="searchParams.pageIndex"
                        :page-size="searchParams.pageSize">
                </el-pagination>
            </PageBar>
        </div>
        <!--申込状況更新テンプ-->
        <el-dialog title="申込状況" :visible.sync="aptstsForm.isVisible" width="45%">
            <el-form :model="aptstsForm" label-width="120px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="状況">
                            <el-select size="small" v-model="aptstsForm.aptsts" style="width: 90%;" :disabled="this.accountInfo.role > 3">
                                <el-option v-for="item in appStatusOptions" 
                                  :key="item.value" 
                                  :label="item.label" 
                                  :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="CC">
                            <el-input size="small" v-model.trim="aptstsForm.aptmcc" placeholder="メールアドレスを入力してください。" style="width: 90%;" :disabled="this.accountInfo.role > 3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="差戻コメント">
                            <el-input size="small" v-model.trim="aptstsForm.aptcmd" placeholder="ご入力してください。" style="width: 90%;" :disabled="this.$route.query.status > 3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer" v-show="accountInfo.role <= 3 && aptstsForm.aptFlg == 0">
                <el-button size="small" round @click="aptstsForm.isVisible = false">一覧へ戻る</el-button>
                <el-button size="small" round type="info" @click="clearApt">クリア</el-button>
                <el-button size="small" round type="primary" class="pp-btn" @click="updateAppStatus">申込状況変更</el-button>
            </div>
        </el-dialog>
        <!--OP状況更新テンプ-->
        <el-dialog title="OP状況" :visible.sync="opsttsForm.isVisible" width="45%">
            <el-form :model="opsttsForm" label-width="120px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="状況">
                            <el-radio-group v-model="opsttsForm.opstts" style="width: 90%;margin-left: 20px;">
                                <el-radio :label=1>　有　</el-radio>
                                <el-radio :label=2>紐づけ待ち</el-radio>
                                <el-radio :label=3>入稿済</el-radio>
                                <el-radio :label=4>完了</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="opsttsForm.opstts == 4">
                    <el-col :span="24">
                        <el-form-item label="OP詳細">
                            <el-input size="small" type="textarea" :rows="3" v-model="opsttsForm.opscmd" placeholder="ご入力してください。" style="width: 90%;"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer" v-show="accountInfo.role <= 3">
                <el-button size="small" round @click="opsttsForm.isVisible = false">一覧へ戻る</el-button>
                <el-button size="small" round type="info" @click="clearOp">クリア</el-button>
                <el-button size="small" round type="primary" class="pp-btn" @click="updateOpStatus">OP状況変更</el-button>
            </div>
        </el-dialog>
        <!--申込日更新テンプ-->
        <el-dialog title="申込日変更" :visible.sync="apltdtForm.isVisible" width="45%">
            <el-form :model="apltdtForm" label-width="120px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="申込日">
                            <el-date-picker size="small" type="date" v-model="apltdtForm.apltdt" placeholder="ご入力してください。" 
                                format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" style="width: 90%;" :disabled="this.accountInfo.role > 3">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer" v-show="accountInfo.role <= 3">
                <el-button size="small" round @click="apltdtForm.isVisible = false">一覧へ戻る</el-button>
                <el-button size="small" round type="info" @click="clearAdt">クリア</el-button>
                <el-button size="small" round type="primary" class="pp-btn" @click="updateApltdt">更新</el-button>
            </div>
        </el-dialog>
        <!--備考更新テンプ-->
        <el-dialog title="備考変更" :visible.sync="cstcmdForm.isVisible" width="45%">
            <el-form :model="cstcmdForm" label-width="120px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="備考内容">
                            <el-input size="small" type="textarea" :rows="3" v-model="cstcmdForm.cstcmd" placeholder="ご入力してください。" style="width: 90%;" :disabled="this.accountInfo.role > 3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer" v-show="accountInfo.role <= 3">
                <el-button size="small" round @click="cstcmdForm.isVisible = false">一覧へ戻る</el-button>
                <el-button size="small" round type="info" @click="clearCmd">クリア</el-button>
                <el-button size="small" round type="primary" class="pp-btn" @click="updateCstcmd">更新</el-button>
            </div>
        </el-dialog>
        <!--エクスポートテンプ-->
        <el-dialog title="エクスポート" :visible.sync="exportForm.isVisible" width="45%">
            <el-form ref="exportForm"  :model="exportForm" :rules="exportRules" label-width="120px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="期間" required="true">
                            <el-form-item prop="dateFrom">
                                <el-date-picker size="small" type="month" v-model="exportForm.dateFrom" placeholder="開始年月" 
                                    format="yyyy/MM" value-format="yyyy/MM" style="width: 160px;">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item prop="dateTo" style="position: absolute;top: 0;left: 24%;">
                                <el-date-picker size="small" type="month" v-model="exportForm.dateTo" placeholder="終了年月" 
                                    format="yyyy/MM" value-format="yyyy/MM" style="width: 160px;">
                                </el-date-picker>
                            </el-form-item>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" round @click="exportForm.isVisible = false">一覧へ戻る</el-button>
                <el-button size="small" round type="info" @click="clearExport">クリア</el-button>
                <el-button size="small" round type="primary" class="pp-btn" @click="confirmExport">確認</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import SearchCriterial from "@/components/robot/taskCenter/SearchCriterialPp.vue";
    import PageBar from "@/components/common/PageBar.vue";
    import CargoPickList from "@/components/robot/taskCenter/CargoPickList.vue";
    import {listCustomer, countInfo, cancelCustomer, delCustomer, rerunCustomer, getAptstsInfo, updateAptsts, 
        getOpsttsInfo, updateOpstts, getApltdtInfo, updateApltdt, getCstcmdInfo, updateCstcmd} from "@/api/customer";
    import {isValidResponse} from "@/utils/commonUtils";
    import {ROUTER_PATH} from "@/constants/common.js";
    import {mapState} from "vuex";
    const defaultSearchParams = {
        customerName: "",
        statusArray: [-1,0,1,2,3,4,99],
        aptsts: null,
        opstts: null,
        groupType: null,
        applyDate: null,
        cdstrd: null,
        dateTimeFrom: null,
        dateTimeTo: null,
        isExpand: false,
        pageIndex: 1,
        pageSize: 20, 
    };

    export default {
        name: "Monitor",
        components: {
            SearchCriterial,
            PageBar,
            CargoPickList
        },
        data: () => {
            return {
                currentData: null,
                searchParams: {...defaultSearchParams},
                totalCount: 0,
                subCount: 0,
                apvlCount: 0,
                errCount: 0,
                refCount: 0,
                items: [],
                expands: [],
                // appMailCcOptions: [],
                appStatusOptions: [
                    {value:-1, label: "　"},
                    {value:0, label: "差戻中"},
                    {value:1, label: "完了"}
                ],
                aptstsForm: {
                    isVisible: false,
                    id: null,
                    aptFlg: 0,
                    aptsts: -1,
                    aptmcc: "",
                    aptcmd: "",
                },
                opsttsForm: {
                    isVisible: false,
                    id: null,
                    opFlg: 0,
                    opstts: 0,
                    opscmd: "",
                },
                apltdtForm: {
                    isVisible: false,
                    id: null,
                    apltdt: null,
                },
                cstcmdForm: {
                    isVisible: false,
                    id: null,
                    cstcmd: "",
                },
                exportForm: {
                    isVisible: false,
                    dateFrom: null,
                    dateTo: null,
                },
                exportRules: {
                    dateFrom: [
                        { required: true, message: "開始年月を入力してください。", trigger: "blur" },
                    ],
                    dateTo: [
                        { required: true, message: "終了年月を入力してください。", trigger: "blur" },
                    ]
                }
            };
        },
        mounted() {
        },
        created: function () {
            var tcid = this.$route.query.cid;

            if (tcid && tcid != "") {
                this.searchParams.contractNo = tcid;
            }
            this.searchParams.groupId = this.accountInfo.groupId+"";
            this.refreshData();
        },
        computed: {
            ...mapState(["accountInfo"]),
        },
        methods: {
            toBreak(val) {
                if (val) return val.split(",").join("<br/>");
            },
            toBreakNew(val) {
                if (val) return val.split("\n").join("<br/>");
            },
            tableRowClassName({row, rowIndex}) {
                if (row.cldlcd == 1 || row.prcsst == -1) {
                    return 'delete-row';
                }
                return '';
            },
            formatDateHHmi(objDate) {
                var zero = function (value) {
                    if (value < 10) {
                        return '0' + value;
                    }
                    return value;
                };
                if (!objDate) return '';
                var myDate = new Date(objDate);
                var year = myDate.getFullYear();
                var month = zero(myDate.getMonth() + 1);
                var day = zero(myDate.getDate());
                var hour = zero(myDate.getHours());
                var minutes = zero(myDate.getMinutes());
                return  year + "-" + month + "-" + day + " " + hour + ":" + minutes;
            },
            formatApplyDate(objDate) {
                var zero = function (value) {
                    if (value < 10) {
                        return '0' + value;
                    }
                    return value;
                };
                var myDate = new Date(objDate);
                var year = myDate.getFullYear();
                var month = zero(myDate.getMonth() + 1);
                var day = zero(myDate.getDate());
                return  year + "-" + month + "-" + day;
            },
            refreshData() {
                let sParams = this.$route.params.searchParams;
                if (sParams) {
                    sParams = this.base64decode(sParams);
                    this.searchParams = JSON.parse(sParams);
                    this.$route.params.searchParams = "";
                }
                countInfo({}).then((response) => {
                    if (isValidResponse(response)) {
                        this.subCount = response.data.subCount;
                        this.apvlCount = response.data.apvlCount;
                        this.errCount = response.data.errCount;
                        this.refCount = response.data.refCount;
                    }
                });
                listCustomer({
                    employId: this.accountInfo.id,
                    ...this.searchParams,
                }).then((response) => {
                    if (isValidResponse(response)) {
                        this.totalCount = response.data.totalCount;
                        this.items = response.data.items;
                    }
                });
            },
            handleCurrentChange(val) {
                this.searchParams = {...this.searchParams, pageIndex: val};
                this.searchParams.isExpand = false;
                this.refreshData();
            },
            handleChangeSearchParams(searchParams) {
                this.searchParams = searchParams;
            },
            handleAdd(){
                this.$router.push(ROUTER_PATH.ADD_CUSTOMER);
            },
            handleExport() {
                this.exportForm.isVisible = true
            },
            clearExport() {
                this.exportForm.dateFrom = null;
                this.exportForm.dateTo = null;
            },
            confirmExport() {
                this.$refs["exportForm"].validate(valid => {
                    if (valid) {
                        if (this.exportForm.dateFrom > this.exportForm.dateTo) {
                            this.$message({
                                message: "開始年月より過去の終了年月が設定できません。",
                                type: "error",
                            });
                            return;
                        } 
                        this.$confirm('案件情報をエクスポートします。よろしいですか？', '提示', {
                            confirmButtonText: '確認',
                            cancelButtonText: '戻る',
                            type: 'warning'
                        }).then(() => {
                            let url = process.env.VUE_APP_BASE_API + "/customer/exportMonthData?dateFrom=" + this.exportForm.dateFrom + "&dateTo=" + this.exportForm.dateTo;
                            window.open(url, '_blank'); // 新开窗口下载
                        });
                    }
                })
            },
            handleSearch() {
                this.searchParams = {...this.searchParams, pageIndex: 1};
                this.refreshData();
            },
            handleSearchSub() {
                this.searchParams = {...defaultSearchParams};
                this.searchParams.statusArray = [1];
                this.searchParams = {...this.searchParams, pageIndex: 1};
                this.refreshData();
            },
            handleSearchApvl() {
                this.searchParams = {...defaultSearchParams};
                this.searchParams.statusArray = [2,3,4];
                this.searchParams = {...this.searchParams, pageIndex: 1};
                this.refreshData();
            },
            handleSearchRef() {
                this.searchParams = {...defaultSearchParams};
                this.searchParams.aptsts = 0;
                this.searchParams.statusArray = defaultSearchParams.statusArray
                this.searchParams = {...this.searchParams, pageIndex: 1};
                this.refreshData();
            },
            handleSearchErr() {
                this.searchParams = {...defaultSearchParams};
                this.searchParams.statusArray = [99];
                this.searchParams = {...this.searchParams, pageIndex: 1};
                this.refreshData();
            },
            handleReset() {
                this.searchParams = {...defaultSearchParams};
                this.searchParams.groupId = this.accountInfo.groupId+"";
                this.refreshData();
            },
            handleExpand() {
                this.expands = [];
                if (this.searchParams.isExpand) {
                    this.items.forEach(element => {
                        this.expands.push(element.mcltid);
                    });
                }
            },
            handleCont(mcltid) {
                this.$confirm('該当案件情報を継続します。よろしいですか？', '提示', {
                    confirmButtonText: '確認',
                    cancelButtonText: '戻る',
                    type: 'warning'
                }).then(() => {
                    this.showEditView(mcltid, 3);
                });
            },
            handleUpdate(mcltid) {
                this.$confirm('該当案件情報を修正します。よろしいですか？', '提示', {
                    confirmButtonText: '確認',
                    cancelButtonText: '戻る',
                    type: 'warning'
                }).then(() => {
                    this.showEditView(mcltid, 1);
                });
            },
            handleConfirm(mcltid) {

                this.showEditView(mcltid, 4);
            },
            handleCopy(mcltid) {
                this.$confirm('該当案件情報をコピーします。よろしいですか？', '提示', {
                    confirmButtonText: '確認',
                    cancelButtonText: '戻る',
                    type: 'warning'
                }).then(() => {
                    this.showEditView(mcltid, 2);
                });
            },
            handleCancel(mcltid) {
                this.$confirm('該当案件情報をキャンセルします。よろしいですか？', '提示', {
                    confirmButtonText: '確認',
                    cancelButtonText: '戻る',
                    type: 'warning'
                }).then(() => {
                    cancelCustomer({
                        employId: this.accountInfo.id,
                        customerId: mcltid,
                        groupId: this.accountInfo.groupId
                    }).then((response) => {
                        if (isValidResponse(response)) {
                            this.refreshData();
                        }
                    });
                });
            },
            handleDelete(mcltid) {
                this.$confirm('該当案件情報を削除します。よろしいですか？', '提示', {
                    confirmButtonText: '確認',
                    cancelButtonText: '戻る',
                    type: 'warning'
                }).then(() => {
                    delCustomer({
                        employId: this.accountInfo.id,
                        customerId: mcltid,
                        groupId: this.accountInfo.groupId
                    }).then((response) => {
                        if (isValidResponse(response)) {
                            this.refreshData();
                        }
                    });
                });
            },
            handleRecover(mcltid) {
                this.$confirm('該当案件情報を回復します。よろしいですか？', '提示', {
                    confirmButtonText: '確認',
                    cancelButtonText: '戻る',
                    type: 'warning'
                }).then(() => {
                    this.showEditView(mcltid, 5);
                });
            },
            handleRerun(mcltid) {
                this.$confirm('該当案件のRPA処理を再実行します。よろしいですか？', '提示', {
                    confirmButtonText: '確認',
                    cancelButtonText: '戻る',
                    type: 'warning'
                }).then(() => {
                    rerunCustomer({
                        employId: this.accountInfo.id,
                        customerId: mcltid,
                        groupId: this.accountInfo.groupId
                    }).then((response) => {
                        if (isValidResponse(response)) {
                            this.refreshData();
                        }
                    });
                });
            },
            handlePDF(command) {
                if(command.cmd == 'pp') {
                    this.downloadFile(command.id);
                } else if (command.cmd == 'pa') {
                    this.downloadFile(command.id);
                } else {
                    this.downloadPdfFile(command.id);
                }

            },
            downloadFile(mcltid) {
                let url = process.env.VUE_APP_BASE_API + "/customer/downloadPdf?id=" + mcltid + "&groupId=" + this.accountInfo.groupId;
                //let url = process.env.VUE_APP_BASE_API + "/customer/preview?id=" + mcltid + "&groupId=" + this.accountInfo.groupId;
                window.open(url, '_blank'); // 新开窗口下载
            },
            downloadPdfFile(mcltid) {
                let url = process.env.VUE_APP_BASE_API + "/customer/downloadPdfCs?id=" + mcltid + "&groupId=" + this.accountInfo.groupId;
                window.open(url, '_blank'); // 新开窗口下载
            },
            downloadErrImg(mcltid) {
                let url = process.env.VUE_APP_BASE_API + "/customer/downloadErrImg?id=" + mcltid + "&groupId=" + this.accountInfo.groupId;
                window.open(url, '_blank'); // 新开窗口下载
            },
            showEditView(mcltid, status) {
                this.$router.push({ path: ROUTER_PATH.ADD_CUSTOMER, query: { id: mcltid, status: status }});
            },
            getImgUrl(mcltid){
                let url = process.env.VUE_APP_BASE_API + "/customer/downloadErrImg?id=" + mcltid + "&groupId=" + this.accountInfo.groupId;
                return url;
            },
            showUpdAptsts(id) {
                this.aptstsForm.isVisible = true;
                this.aptstsForm.id = id;
                this.aptstsForm.aptFlg = 0;
                this.aptstsForm.aptsts = -1;
                this.aptstsForm.aptmcc = "";
                this.aptstsForm.aptcmd = "";
                // this.queryPpUserList(null);
                getAptstsInfo({
                    id: id
                }).then(response => {
                    this.aptstsForm.aptsts = response.data.aptsts;
                    this.aptstsForm.aptmcc = response.data.aptmcc;
                    this.aptstsForm.aptcmd = response.data.aptcmd;
                    if (this.aptstsForm.aptsts === 1) {
                      this.aptstsForm.aptFlg = 1;
                    }
                });
            },
            showUpdOpstts(id) {
                this.opsttsForm.isVisible = true;
                this.opsttsForm.id = id;
                this.opsttsForm.opFlg = 0;
                this.opsttsForm.opstts = 0;
                this.opsttsForm.opscmd = "";
                getOpsttsInfo({
                    id: id
                }).then(response => {
                    this.opsttsForm.opstts = response.data.opstts;
                    this.opsttsForm.opscmd = response.data.opscmd;
                    if (this.opsttsForm.opstts === 3) {
                      this.opsttsForm.opFlg = 1;
                    }
                });
            },
            showUpdApltdt(id) {
                if (this.accountInfo.role <= 3) {
                    this.apltdtForm.isVisible = true;
                    this.apltdtForm.id = id;
                    this.apltdtForm.apltdt = null;
                    getApltdtInfo({
                        id: id
                    }).then(response => {
                        this.apltdtForm.apltdt = response.data.apltdt;
                    });
                }
            },
            showUpdCstcmd(id) {
                if (this.accountInfo.role <= 3) {
                    this.cstcmdForm.isVisible = true;
                    this.cstcmdForm.id = id;
                    this.cstcmdForm.cstcmd = "";
                    getCstcmdInfo({
                        id: id
                    }).then(response => {
                        this.cstcmdForm.cstcmd = response.data.cstcmd;
                    });
                }
            },
            clearApt() {
                this.aptstsForm.aptsts = -1;
                this.aptstsForm.aptmcc = "";
                this.aptstsForm.aptcmd = "";
            },
            clearOp() {
                this.opsttsForm.opstts = 0;
                this.opsttsForm.opscmd = "";
            },
            clearAdt() {
                this.apltdtForm.apltdt = null;
            },
            clearCmd() {
                this.cstcmdForm.cstcmd = "";
            },
            updateAppStatus() {
                this.$confirm('申込状況を更新します。よろしいですか？', '提示', {
                    confirmButtonText: '確認',
                    cancelButtonText: '戻る',
                    type: 'warning'
                }).then(() => {
                    var data = {
                        customerVo: {
                            mcltid: this.aptstsForm.id,
                            aptsts: this.aptstsForm.aptsts,
                            aptmcc: this.aptstsForm.aptmcc,
                            aptcmd: this.aptstsForm.aptcmd,
                        },
                        employId: this.accountInfo.id,
                        groupId: this.accountInfo.groupId
                    }
                    updateAptsts(data).then((response) => {
                        this.$message({
                            message: response.message,
                            type: "success"
                        });
                        this.aptstsForm.isVisible = false;
                        this.refreshData();
                    });
                });
            },
            updateOpStatus() {
                this.$confirm('OP状況を更新します。よろしいですか？', '提示', {
                    confirmButtonText: '確認',
                    cancelButtonText: '戻る',
                    type: 'warning'
                }).then(() => {
                    var data = {
                        customerVo: {
                            mcltid: this.opsttsForm.id,
                            opstts: this.opsttsForm.opstts,
                            opscmd: this.opsttsForm.opscmd,
                        },
                        employId: this.accountInfo.id,
                        groupId: this.accountInfo.groupId
                    }
                    updateOpstts(data).then((response) => {
                        this.$message({
                            message: response.message,
                            type: "success"
                        });
                        this.opsttsForm.isVisible = false;
                        this.refreshData();
                    });
                });
            },
            updateApltdt() {
                this.$confirm('申込日を更新します。よろしいですか？', '提示', {
                    confirmButtonText: '確認',
                    cancelButtonText: '戻る',
                    type: 'warning'
                }).then(() => {
                    var data = {
                        customerVo: {
                            mcltid: this.apltdtForm.id,
                            apltdt: this.apltdtForm.apltdt,
                        },
                        groupId: this.accountInfo.groupId
                    }
                    updateApltdt(data).then((response) => {
                        this.$message({
                            message: response.message,
                            type: "success"
                        });
                        this.apltdtForm.isVisible = false;
                        this.refreshData();
                    });
                });
            },
            updateCstcmd() {
                this.$confirm('備考を更新します。よろしいですか？', '提示', {
                    confirmButtonText: '確認',
                    cancelButtonText: '戻る',
                    type: 'warning'
                }).then(() => {
                    var data = {
                        customerVo: {
                            mcltid: this.cstcmdForm.id,
                            cstcmd: this.cstcmdForm.cstcmd,
                        },
                        groupId: this.accountInfo.groupId
                    }
                    updateCstcmd(data).then((response) => {
                        this.$message({
                            message: response.message,
                            type: "success"
                        });
                        this.cstcmdForm.isVisible = false;
                        this.refreshData();
                    });
                });
            },
        },
    };
</script>
<style>
    .font-date {
        color: #909399;
        font-size: 11px;
    }

    .font-num {
        color: #409EFF;
    }

    .font-id {
        color: #409EFF;
        font-weight: bold;
    }
    .el-table .delete-row {
        background: #DCDFE6;
    }

    .el-table .success-row {
        background: #f0f9eb;
    }
</style>
